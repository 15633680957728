import "core-js/stable";
import "regenerator-runtime/runtime";
import './public-path';
import { Survey } from './sdk/Survey';
import { initLoggerFactory, createLogger, eLogLevel } from "@888webteam/logger-core";
import { ConsoleLoggerFactory } from "@888webteam/logger-console";
import v4 from "uuid/v4";

initLoggerFactory(new ConsoleLoggerFactory(eLogLevel.Debug));
let logger = createLogger("Survey");

const _scriptId = v4();

global.Survey = function (settings) {

    logger.log(`using Survey ${VERSION}`);

    if (settings != null &&
        settings.context != null) {

        if (typeof settings.context.sessionId === "undefined") {
            settings.context.sessionId = v4();
        }

        if (typeof settings.context.scriptId === "undefined") {
            settings.context.scriptId = _scriptId;
        }
    }

    return new Survey(settings);
};

global.initZoid = function (then) {

    import(/* webpackChunkName: "zoid" */ './zoid/SurveyZoidComponent').then(function (x) {
        x.zoidCreate();
        then();
    });
}