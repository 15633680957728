//TODO - Clean and adjust this also

let tracker = null;

if (window.TrackJS) {
    tracker = window.TrackJS;
}

let isIVTracker = tracker && (tracker.IV || false);

if (isIVTracker) {
    tracker.configure({
        version: VERSION
    });
}

function setTrackJsContext(context, clientConfig) {

    if (tracker) {
        if (context !== null) {

            if (isIVTracker && context.sessionId !== null) {
                tracker.configure({
                    sessionId: context.sessionId
                });
            }

            tracker.addMetadata("Survey-SessionID", context.sessionId);
            tracker.addMetadata("Survey-brandId", context.brandId);
            tracker.addMetadata("Survey-subBrandId", context.subBrandId);
            tracker.addMetadata("Survey-productPackage", context.productPackage);
            tracker.addMetadata("Survey-systemId", context.systemId);
            tracker.addMetadata("Survey-lang", context.lang);
            tracker.addMetadata("Survey-clientVersion", context.clientVersion);
            tracker.addMetadata("Survey-mode", context.mode);
            tracker.addMetadata("Survey-registrationCountry", context.registrationCountry);
            tracker.addMetadata("Survey-offeringId", context.offeringId);
        }

        if (clientConfig != null) {
            tracker.addMetadata("Survey-displayMode", clientConfig.displayMode);
            tracker.addMetadata("Survey-uploadDocMethod", clientConfig.uploadDocMethod);
            tracker.addMetadata("Survey-showCompleteButtons", clientConfig.showCompleteButtons);
        }
    }
}

function setTrackJsState(state) {

    if (tracker) {
        if (state !== null) {
            tracker.console.info("Survey-StateChanged", state);
        }
    }
}

function setTrackJsUserData(userData) {

    if (tracker) {
        if (userData !== null) {

            if (isIVTracker && typeof userData.Cid === "number") {
                tracker.configure({
                    userId: userData.Cid.toString()
                });
            }

            tracker.addMetadata("Survey-CID", userData.Cid);
            tracker.addMetadata("Survey-AuthenticationId", userData.AuthenticationId);
            tracker.addMetadata("Survey-InitialSessionId", userData.InitialSessionId);
            tracker.addMetadata("Survey-IsPreview", userData.IsPreview);
            tracker.addMetadata("Survey-RegulationType", userData.RegulationType);
        }
    }
}

function setTrackJsSurveyId(surveyId) {
    if (tracker) {
        tracker.addMetadata("Survey-SurveyId", surveyId);
    }
}

function setTrackJsSurveySportType(surveySportType, surveySportTypeName) {
    if (tracker) {
        tracker.addMetadata("Survey-SportType", surveySportType);
        tracker.addMetadata("Survey-SportTypeName", surveySportTypeName);
    }
}

function trackJsInfo(event, param) {
    if (tracker) {
        tracker.console.info("Survey:", event, param);
    }
}

export { setTrackJsContext, setTrackJsState, setTrackJsUserData, trackJsInfo, setTrackJsSurveyId, setTrackJsSurveySportType }