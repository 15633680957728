import { ElasticMonitoring } from "./ElasticMonitoring";
import { setTrackJsContext, setTrackJsState, setTrackJsUserData, trackJsInfo, setTrackJsSurveyId, setTrackJsSurveySportType } from "./trackjs";
import { eSportTypeNameMapping } from "../app/enums";

let _state = null;
let _context = null;
let _config = null;

let _eventData = {
    surveyVersion: VERSION
};

let _elasticMonitoring = new ElasticMonitoring(`${__webpack_public_path__}api/survey/event/`, "web-survey");

function setContext(context, config) {

    if (context !== null) {

        _context = Object.assign(_context || {}, context);

        _elasticMonitoring.setClientLanguage(context.lang);
        _elasticMonitoring.setClientVersion(context.clientVersion);
        _elasticMonitoring.setSubBrandId(context.subBrandId);
        _elasticMonitoring.setBrandId(context.brandId);
        _elasticMonitoring.setProductPackage(111);
        _elasticMonitoring.setSystemId(context.systemId);

        if (typeof context.sessionId === "string") {
            _elasticMonitoring.setCustomHeaders({ "Survey-SessionId": context.sessionId });
        }
    }

    if (config !== null) {
        _config = Object.assign(_config || {}, config);
    }

    setTrackJsContext(_context, _config);
}

function setMonitoringState(state) {
    _state = Object.assign(_state || {}, state);
    setTrackJsState(_state);
}

function setMonitoringUserData(userData) {

    if (userData !== null &&
        typeof userData === "object") {
        _elasticMonitoring.setCid(userData.Cid);
        _elasticMonitoring.setGlobalSessionId(userData.InitialSessionId);
        _elasticMonitoring.setPlayerSessionId(userData.AuthenticationId);
        _elasticMonitoring.setRegulationId(userData.RegulationType);

        setTrackJsUserData(userData);
    }
}

function setMonitoringSurveyContext(surveyId, surveyType) {
    if (typeof surveyId === "number") {
        _eventData.surveyId = surveyId;
        setTrackJsSurveyId(surveyId);
    }

    if (typeof surveyType === "number") {       
        _eventData.surveySportType = surveyType;
        _eventData.surveySportTypeName = eSportTypeNameMapping[surveyType];
        setTrackJsSurveySportType(_eventData.surveySportType, _eventData.surveySportTypeName);           
    }
}

function getMonitoringEventData() {
    return _eventData;
}

function monitor(eventName, eventProps) {

    try {
        trackJsInfo(eventName, eventProps);

        let eventData = Object.assign({}, _eventData);

        if (typeof eventProps === "object") {
            eventData = Object.assign(eventData, eventProps);
        }

        if (_state !== null) {
            eventData.state = _state;
        }

        if (_context !== null) {
            eventData.context = _context;
        }

        if (_config !== null) {
            eventData.config = _config;
        }

        _elasticMonitoring.reportInfo(eventName, eventData);
    }
    catch (e) {
        // ignore
    }
}

export { monitor, setContext, setMonitoringState, setMonitoringUserData, setMonitoringSurveyContext, getMonitoringEventData };