let eMode = {
    Undefined: 0,
    NotLoggedIn: 1,
    LoggedIn: 2
}

let eScreen = {
    LoadingScreen: 0,
    BeforeSubmit: 2,
    AfterSubmit: 3,
    DuringGames: 4,
    InactiveMode: 5,
    BeforeLoginScreen: 6,
    BeforeSubmitNotLoggedIn: 7,
    BetSlip: 8,
    FullTermsAndConditions : 9

}

let eGeneralErrorCode = {
    Success: 0,
    GeneralError: 1,
    InvalidParameter: 7,
    TemporaryProblem: 15,
}

let eSubmitErrorCode = {
    DuplicateKey: 20,
    PlayerIsNotAgeVerified: 21,
    PlayerIsNotAddressVerified: 22,
    PlayerIsNotPromotionPermitted: 23,
    PlayerIsNotRegulatorApproved: 28
}

let eGetSurveyErrorCode = {
    LastSubmissionDateAlreadyPassed: 24,
    SurveyNotFoundForBrandAndDates: 25,
    RegistrationCountryIsNotUK: 27
}

let eSurveyAppErrorCode = {
    LastLastSubmissionDateAlreadyPassedAtSubmit: 101
}

const eConstants = {
    HomeDrawAwayNumberOfQuestions: 8,
    PointSpreadTotalNumberOfQuestions: 10,
    BetSlipDefaultAmount: 10,
    BetSlipTypeSingle: "single",
    BetSlipTypeDouble: "double",
    BetSlipTypeTreble: "treble",
    BetSlipTypeAcca: "acca",
    BetSlipTypeParlay: "parlay",
    BetSlipTypeParlaySingle: "parlaySingle",
    BetSlipFirstScreenId: 1,
    BetSlipSecondScreenId: 2,
    flexRepeaterTemplateId: 362187
}

const eLanguagesWithComDifferences = {
    Spanish: "SPA",
    German: "GER"
}

const eOddsType = {
    Decimal: 0,
    American: 1,
    Fractional: 2,
}

let eLanguageComponent = [
    //COM languages
    {
        lang: "ENG", componentID: 492743, publicationID: 1755
    },
    {
        lang: "SPA_COM", componentID: 492743, publicationID: 1757
    },
    {
        lang: "GER_COM", componentID: 492743, publicationID: 1754
    },
    {
        lang: "FRE", componentID: 492743, publicationID: 1761
    },
    {
        lang: "RUS", componentID: 492743, publicationID: 1766
    },
    {
        lang: "FIN", componentID: 492743, publicationID: 1759
    },
    {
        lang: "POR", componentID: 492743, publicationID: 1764
    },
      //Regulated languages
    {
        lang: "RUM", componentID: 498932, publicationID: 1646
    },  
    {
        lang: "GER", componentID: 526693, publicationID: 1970
    },
    {
        lang: "DAN", componentID: 498836, publicationID: 1948
    },
    {
        lang: "ITA", componentID: 498956, publicationID: 1944
    },
    {
        lang: "SPA", componentID: 498964, publicationID: 1943
    },
    {
        lang: "SWE", componentID: 498969, publicationID: 1893
    }, 
    // Colorado
    {
        lang: "ENG_CO", componentID: 525528, publicationID: 2011
    }
]


const eSupportedLanguages = {
    English: "ENG",
    Spanish: "SPA",
    Romanian: "RUM",
    German: "GER",
    Danish: "DAN",
    Italian: "ITA",
    French: "FRE",
    Russian: "RUS",
    Finnish: "FIN",
    Portuguese: "POR"
}

const eBrand = {
    _sportCom: 8,
    _888es: 58,
    _888dk: 75,
    _888ro: 76,
    _888de: 84,
    _888it: 31,
    _pokerCom: 1,
    _888siCO: 86,
    _888caOn: 87,
    _888siVA: 89,
    _888siIN: 90,
    _888siMI: 91,
    _MRG_DK: 94,
}

const eSportType = {
    Soccer: 0,
    AmericanFootball: 1,
    Basketball: 2,
    Mixed: 11
}

const eSportTypeNameMapping = {
    "0": "Soccer",
    "1": "American Football",
    "2": "Basketball",
    "11": "Mixed"
}

const eQuestionType = {
    Unknown : 0,
    HomeDrawAway : 1,
    PointsSpread : 2,
}

const eCountry = {
    GBR: "GBR"
}

const eSportId = {
    AmericanFootball : 6,
    Basketball : 229,
    Baseball : 363,
    IceHockey : 362
}

const eEventStatus = {
    Active : 0,
    Finished : 1,
    Canceled : 2,
    Unknown : 3
}

const eThemeScheme = {
    Default : 0,
    White : 1,
    Dark : 2,
    Mrg : 3    
}

export {
    eMode, eScreen, eGeneralErrorCode, eSubmitErrorCode, eGetSurveyErrorCode,
    eSurveyAppErrorCode, eConstants, eSupportedLanguages, eBrand, eSportType, eCountry,
    eLanguagesWithComDifferences, eLanguageComponent, eQuestionType, eOddsType, eSportId, eSportTypeNameMapping, eEventStatus, eThemeScheme
}