import { monitor, setContext } from "../monitoring/monitoring";
import { createLogger } from "@888webteam/logger-core";

export class Survey {

    constructor(settings) {

        this._logger = createLogger("SurveySdk");

        setContext(settings.context, settings.config);
        monitor("Initializing");

        this.settings = settings || {};
        this.context = this.settings.context || {};
        this.config = this.settings.config || {};

        this._logger.log("Initializing", this.context);

        this.callbacks = {
            onDownloadAppClick: function () {
                this.raiseonDownloadAppClick();
            }.bind(this),

            onLoginButtonClick: function () {
                this.raiseOnLoginButtonClick();
            }.bind(this),

            onComplete: function () {
                this.raiseOnComplete();
            }.bind(this),

            onError: function (err) {
                this.raiseOnError(err);
            }.bind(this),

            onReady: function (isSuccess) {
                this.raiseOnReady(isSuccess);
            }.bind(this),

            onVerifyClick: function () {
                this.raiseOnVerifyClick();
            }.bind(this),

            onBetSlipClick: function (betSlipInfo) {
                this.raiseOnBetSlipClick(betSlipInfo);
            }.bind(this),
        }
    }

    raiseonDownloadAppClick() {
        if (typeof this.settings.onDownloadAppClick === "function") {
            this.settings.onDownloadAppClick();
        }
    }

    raiseOnLoginButtonClick() {
        if (typeof this.settings.onLoginButtonClick === "function") {
            this.settings.onLoginButtonClick();
        }
    }

    raiseOnComplete() {
        if (typeof this.settings.onComplete === "function") {
            this.settings.onComplete();
        }
    }

    raiseOnError(err) {
        if (typeof this.settings.onError === "function") {
            this.settings.onError(err);
        }
    }

    raiseOnReady(isSuccess) {
        if (typeof this.settings.onReady === "function") {
            this.settings.onReady(isSuccess);
        }
    }

    raiseOnVerifyClick() {
        if (typeof this.settings.onVerifyClick === "function") {
            this.settings.onVerifyClick();
        }
    }

    raiseOnBetSlipClick(betSlipInfo) {
        if (typeof this.settings.onBetSlipClick === "function") {
            this.settings.onBetSlipClick(betSlipInfo);
        }
    }

    render(selector, inline) {

        monitor("Rendering");

        let container = document.querySelector(selector);

        if (container === null || container === undefined) {
            this.raiseOnError(`unable to find container ${selector}`);
        } else {
            if (container.hasChildNodes()) {
                while (container.firstChild) {
                    container.removeChild(container.firstChild);
                }
            }
            if (typeof inline === "boolean" && inline === true) {
                this.renderReact(selector);
            } else {
                this.renderZoid(selector);
            }
        }
    }

    renderZoid(selector) {

        import(/* webpackChunkName: "zoid" */ '../zoid/SurveyZoidComponent').then(function (x) {

            let surveyZoidComponent = x.zoidCreate();

            surveyZoidComponent({
                context: this.context,
                config: this.config,
                onDownloadAppClick : this.callbacks.onDownloadAppClick,
                onLoginButtonClick : this.callbacks.onLoginButtonClick,
                onVerifyClick: this.callbacks.onVerifyClick,
                onBetSlipClick: this.callbacks.onBetSlipClick,
                onComplete: this.callbacks.onComplete,
                onError: this.callbacks.onError,
                onUploadDocument: this.callbacks.onUploadDocument,
                onReady: this.callbacks.onReady
            }).render(selector);
        }.bind(this));
    }

    renderReact(selector) {
        import(/* webpackChunkName: "react" */ '../app/index').then(function (x) {
            x.render(selector, this.context, this.config, this.callbacks);
        }.bind(this));
    }
}