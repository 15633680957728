import axios from "axios";
import { createLogger } from "@888webteam/logger-core";
const merge = require('deepmerge');

let eEnvironments = {
    Dev: "Dev",
    QA: "QA",
    Production: "Production",
    NotSelected: "NotSelected"
};

let eLevel = {
    trace: "trace",
    debug: "debug",
    information: "information",
    warning: "warning",
    error: "error",
    critical: "critical",
    none: "none"
};

export class ElasticMonitoring {

    constructor(url, target) {

        this._logger = createLogger("ElasticMonitoring");

        this._target = target ? target.toLowerCase() : target;
        this._url = url;
        this._customData = {};
        this._custumHeaders = {};
    }

    setCustomHeaders(customHeaders) {
        this._custumHeaders = Object.assign(this._custumHeaders || {}, customHeaders);
    }

    setClientLanguage(lang) {
        this.setClientData({ language: lang });
    }

    setClientVersion(ver) {
        this.setClientData({ version: ver });
    }

    setSubBrandId(sb) {
        if (typeof sb === "number") {
            this.setClientData({ subBrand: sb });
        }
    }

    setParentProductPackage(v) {
        if (typeof v === "number") {
            this.setClientData({ parentProductPackage: v });
        }
    }

    setProductPackage(v) {
        if (typeof v === "number") {
            this.setClientData({ productPackage: v });
        }
    }

    setRegulationId(v) {
        if (typeof v === "number") {
            this.setClientData({ regulation: v });
        }
    }

    setBrandId(v) {
        if (typeof v === "number") {
            this.setCustomData({ eventHeader: { userData: { brandId: v } } });
        }
    }

    setCid(v) {
        if (typeof v === "number") {
            this.setCustomData({ eventHeader: { userData: { cid: v } } });
        }
    }

    setGlobalSessionId(v) {
        if (typeof v === "number") {
            this.setCustomData({ sessionData: { globalSessionId: v } });
        }
    }

    setPlayerSessionId(v) {
        if (typeof v === "number") {
            this.setCustomData({ sessionData: { playerSessionId: v } });
        }
    }

    setSystemId(v) {
        if (typeof v === "number") {
            this.setCustomData({ serverData: { systemId: v } });
        }
    }

    setSurveyId(v) {
        if (typeof v === "number") {
            this.setCustomData({ eventHeader: { surveyId: v } });
        }
    }

    setClientData(d) {
        this.setCustomData({ clientData: d });
    }

    setCustomData(d) {
        if (d != null && typeof d === "object") {
            this._customData = merge(this._customData, d);
        }
    }

    getDefaultMonitoringObject() {

        // based on http://confluence/display/Infra/Elastic+-+basic+data+structure+for+application+logs

        return {
            eventHeader: {
                eventDateTime: (new Date).toISOString(),
                level: eLevel.information,
                environment: eEnvironments.NotSelected,
                target: this._target
            },

            sessionData: {
                globalSessionId: null,
                playerSessionId: null,
            },

            deviceData: {
                osName: window.navigator.platform,
                userAgent: window.navigator.userAgent,
                browser: window.navigator.appName,
                browserVersion: window.navigator.appVersion,
                culture: window.navigator.language,
                screenResolution: window.innerWidth + 'X' + window.innerHeight
            },

            clientData: {
                url: window.location.href
            }
        }
    }

    async reportEventAsync(monitoringMessage) {
        try {
            let response = await axios.post(this._url, monitoringMessage, { headers: this._custumHeaders });
            this._logger.log("reportEventAsync", response);
        } catch (error) {
            this._logger.error("reportEventAsync", error);
        }
    }

    async reportInfo(eventName, eventData) {
        return await this.report(eLevel.information, eventName, eventData);
    }

    async report(level, eventName, eventData) {

        let monitoringData = this.getDefaultMonitoringObject();

        monitoringData = merge(monitoringData, this._customData);
        monitoringData = merge(monitoringData, { eventData: { event: eventName }, eventHeader: { level: level } });

        if (eventData != null && typeof eventData === "object") {
            monitoringData = merge(monitoringData, { eventData: eventData });
        }

        return await this.reportEventAsync(monitoringData);
    }
}