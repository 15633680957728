/* global __webpack_public_path__ */
/* exported __webpack_public_path__ */

function getAppRootPath() {

    let scripts = document.querySelectorAll("script");
    let appScript = null;

    if (scripts != null) {
        for (let i = 0; i < scripts.length; ++i) {

            let script = scripts[i];

            if (script.src != null && script.src.toLowerCase().endsWith("survey.js")) {
                appScript = script.src.replace(/js\/survey.js/ig, '');
                break;
            }
        }
    }

    return appScript;
}

// eslint-disable-next-line
__webpack_public_path__ = getAppRootPath();